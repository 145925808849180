import React from 'react';
import {Routes, Route} from 'react-router-dom'
import NavBar from './navbar/navbar';
import Home from './components/home';
import Benefits from './components/benefits';
import PayDues from './components/paydues';
import ContactCJA from './components/contactcja';
import SlideShow from './slideshow/slideshow';
import './App.css';
import Logo from './cjalogo/logo';
import CopyRights from './components/copyrights';




function App() {

  return (    
    <div className='background'>
      <div className='container-1'>
        <div className='container-2'>

          <div className='logo'>
            <Logo />
            </div>
              <div className='slideshow'>
                <SlideShow/>
              </div>
              <div className='navbar'>
                  <NavBar/>
              </div>
  
          <div>
            <Routes>
                <Route path='/' element={<Home/>} />
                <Route path='/benefits' element={<Benefits/>} />
                <Route path='/paydues' element={<PayDues/>} />
                <Route path='/contactcja' element={<ContactCJA/>} />
            </Routes>
          </div>
          <div className='copyrights'>
            <CopyRights />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
