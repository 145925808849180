import React, { useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import "./paydues.css";

const PayDues = () => {
  const [selectedOption, setSelectedOption] = useState("1_year");
  const [paymentStatus, setPaymentStatus] = useState(null);
  const initialOption = {
    "client-id":
      "AVvmoBCR_W01cz2E92v8J3K0ts5bKRaK-iOY4PGLaeQiuKbZpSSayzfV7XWk",
    "enable-funding": "venmo",
    currency: "USD",
    components: "buttons",
  };

  // Handle user selection of payment plan
  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  // Determine payment amount based on selected option
  const amount = selectedOption === "1_year" ? "150" : "200";

  return (
    <div className="paypal-card">
      <div className="payment-options">
        <h1 className="h1-dues">Dues for Chicago Jewelers Association</h1>

        <h2 className="h2-dues">
          Use the fields below to pay your annual dues for the Chicago Jewelers
          Association. Annual Dues for Chicago Jewelers Association
        </h2>

        <div className="Select">
          <select value={selectedOption} onChange={handleOptionChange}>
            <option value="1_year">1 Year - $150</option>
            <option value="2_year">2 Year - $200</option>
          </select>
        </div>
      </div>

      <PayPalScriptProvider options={initialOption}>
        <PayPalButtons className="buttons"
          key={selectedOption} // Forces re-render on option change
          style={{
            layout: "vertical",
            color: "gold",
            shape: "rect",
            label: "paypal",
          }}
          fundingSource={undefined}
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: amount,
                  },
                },
              ],
            });
          }}
          onApprove={(data, actions) => {
            return actions.order.capture().then((details) => {
              const name = details.payer.name.given_name;
              setPaymentStatus(`Transaction completed by ${name}`);
              alert(`Transaction completed by ${name}`);
            });
          }}
          onError={(err) => {
            setPaymentStatus("An error occurred during the payment process.");
            console.error("PayPal Checkout Error: ", err);
            alert("An error occurred during the payment process. Please try again.");
          }}
        />
      </PayPalScriptProvider>

      {paymentStatus && <p>{paymentStatus}</p>}
    </div>
  );
};

export default PayDues;






























































// import React from 'react';

// const PayPalButton = () => {
//   const handleApprove = async (data) => {
//     // Call your server to save the order
//     const response = await fetch('http://localhost:8082/api/orders/capture', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({ orderID: data.orderID }),
//     });

//     const orderData = await response.json();
//     if (response.ok) {
//       console.log('Order completed successfully', orderData);
//       alert('Payment successful! Thank you for your purchase.');
//     } else {
//       console.error('Payment capture failed', orderData);
//       alert('Payment failed, please try again.');
//     }
//   };

//   const handleError = (err) => {
//     console.error('PayPal Checkout error:', err);
//     alert('An error occurred during the payment process. Please try again.');
//   };

//   const createOrder = (data, actions) => {
//     // Replace with your own logic for order creation
//     const selectedPlan = document.querySelector('input[name="plan"]:checked').value;
//     const amount = selectedPlan === '1-year' ? '100.00' : '200.00';

//     return actions.order.create({
//       purchase_units: [{
//         amount: {
//           currency_code: 'USD',
//           value: amount,
//         },
//       }],
//     });
//   };

//   return (
//     <div>
//       <h3>Select a Plan:</h3>
//       <label>
//         <input type="radio" name="plan" value="1-year" defaultChecked />
//         1 Year - $100
//       </label>
//       <br />
//       <label>
//         <input type="radio" name="plan" value="2-years" />
//         2 Years - $200
//       </label>
//       <br />
//       <div id="paypal-button-container"></div>

//       <script>
//         {window.paypal.Buttons({
//           createOrder,
//           onApprove: handleApprove,
//           onError: handleError,
//         }).render('#paypal-button-container')}
//       </script>
//     </div>
//   );
// };

// export default PayPalButton;

// import React, { useState } from "react";
// import {
//     PayPalScriptProvider,
//     usePayPalCardFields,
//     PayPalCardFieldsProvider,
//     PayPalButtons,
//     PayPalNameField,
//     PayPalNumberField,
//     PayPalExpiryField,
//     PayPalCVVField,
// } from "@paypal/react-paypal-js";


// const PayDues = () => {

//         const [isPaying, setIsPaying] = useState(false);
//         const initialOptions = {
//             "client-id":
//                 "AXJt2RCIFKfxvMbQYC4CKyM8hie16tqT_SqvOP3cRDtoAgNRxONPl8T0aBdz",
//             "enable-funding": "paylater,venmo,card",
//             "disable-funding": "",
//             "buyer-country": "US",
//             currency: "USD",
//             "data-page-type": "product-details",
//             components: "buttons,card-fields",
//             "data-sdk-integration-source": "developer-studio",
//         };
    
//         const [billingAddress, setBillingAddress] =
//             useState({
//                 addressLine1: "",
//                 addressLine2: "",
//                 adminArea1: "",
//                 adminArea2: "",
//                 countryCode: "",
//                 postalCode: "",
//             });
    
//         function handleBillingAddressChange(field, value) {
//             setBillingAddress((prev) => ({
//                 ...prev,
//                 [field]: value,
//             }));
//         } 
//         async function createOrder() {
//             try {
//                 const response = await fetch("/api/orders", {
//                     method: "POST",
//                     headers: {
//                         "Content-Type": "application/json",
//                     },
//                     // use the "body" param to optionally pass additional order information
//                     // like product ids and quantities
//                     body: JSON.stringify({
//                         cart: [
//                             {
//                                 sku: "1blwyeo8",
//                                 quantity: 2,
//                             },
//                         ],
//                     }),
//                 });
    
//                 const orderData = await response.json();
    
//                 if (orderData.id) {
//                     return orderData.id;
//                 } else {
//                     const errorDetail = orderData?.details?.[0];
//                     const errorMessage = errorDetail
//                         ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
//                         : JSON.stringify(orderData);
    
//                     throw new Error(errorMessage);
//                 }
//             } catch (error) {
//                 console.error(error);
//                 return `Could not initiate PayPal Checkout...${error}`;
//             }
//         }
    
//         async function onApprove(data, actions) {
//             try {
//                 const response = await fetch(
//                     `/api/orders/${data.orderID}/capture`,
//                     {
//                         method: "POST",
//                         headers: {
//                             "Content-Type": "application/json",
//                         },
//                     }
//                 );
    
//                 const orderData = await response.json();
//                 // Three cases to handle:
//                 //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
//                 //   (2) Other non-recoverable errors -> Show a failure message
//                 //   (3) Successful transaction -> Show confirmation or thank you message
    
//                 const transaction =
//                     orderData?.purchase_units?.[0]?.payments?.captures?.[0] ||
//                     orderData?.purchase_units?.[0]?.payments?.authorizations?.[0];
//                 const errorDetail = orderData?.details?.[0];
    
//                 if (
//                     errorDetail ||
//                     !transaction ||
//                     transaction.status === "DECLINED"
//                 ) {
//                     // (2) Other non-recoverable errors -> Show a failure message
//                     let errorMessage;
//                     if (transaction) {
//                         errorMessage = `Transaction ${transaction.status}: ${transaction.id}`;
//                     } else if (errorDetail) {
//                         errorMessage = `${errorDetail.description} (${orderData.debug_id})`;
//                     } else {
//                         errorMessage = JSON.stringify(orderData);
//                     }
    
//                     throw new Error(errorMessage);
//                 } else {
//                     // (3) Successful transaction -> Show confirmation or thank you message
//                     // Or go to another URL:  actions.redirect('thank_you.html');
//                     console.log(
//                         "Capture result",
//                         orderData,
//                         JSON.stringify(orderData, null, 2)
//                     );
//                     return `Transaction ${transaction.status}: ${transaction.id}. See console for all available details`;
//                 }
//             } catch (error) {
//                 return `Sorry, your transaction could not be processed...${error}`;
//             }
//         }
    
//         function onError(error) {
//             // Do something with the error from the SDK
//         }
    
//         return (
        
//             <PayPalScriptProvider className='paypal-style' options={initialOptions}>

//                 <PayPalButtons
//                     createOrder={createOrder}
//                     onApprove={onApprove}
//                     onError={onError}
//                     style={{
//                         shape: "rect",
//                         layout: "vertical",
//                         color: "gold",
//                         label: "paypal",
//                         height: 40,
//                     }} 
//                 />
                
//                 <PayPalCardFieldsProvider
//                     createOrder={createOrder}
//                     onApprove={onApprove}
//                     style={{
//                         input: {
//                             "font-size": "16px",
//                             "font-family": "courier, monospace",
//                             "font-weight": "lighter",
//                             color: "#ccc",
//                         },
//                         ".invalid": { color: "purple" },
//                     }}
//                 >
//                     <PayPalNameField
//                         style={{
//                             input: { color: "blue" },
//                             ".invalid": { color: "purple" },
//                         }}
//                     />
//                     <PayPalNumberField />
//                     <PayPalExpiryField />
//                     <PayPalCVVField />
                    
//                     <input
//                         type="text"
//                         id="card-billing-address-line-2"
//                         name="card-billing-address-line-2"
//                         placeholder="Address line 1"
//                         onChange={(e) =>
//                             handleBillingAddressChange(
//                                 "addressLine1",
//                                 e.target.value
//                             )
//                         }
//                     />
//                     <input
//                         type="text"
//                         id="card-billing-address-line-2"
//                         name="card-billing-address-line-2"
//                         placeholder="Address line 2"
//                         onChange={(e) =>
//                             handleBillingAddressChange(
//                                 "addressLine2",
//                                 e.target.value
//                             )
//                         }
//                     />
//                     <input
//                         type="text"
//                         id="card-billing-address-admin-area-line-1"
//                         name="card-billing-address-admin-area-line-1"
//                         placeholder="Admin area line 1"
//                         onChange={(e) =>
//                             handleBillingAddressChange("adminArea1", e.target.value)
//                         }
//                     />
//                     <input
//                         type="text"
//                         id="card-billing-address-admin-area-line-2"
//                         name="card-billing-address-admin-area-line-2"
//                         placeholder="Admin area line 2"
//                         onChange={(e) =>
//                             handleBillingAddressChange("adminArea2", e.target.value)
//                         }
//                     />
//                     <input
//                         type="text"
//                         id="card-billing-address-country-code"
//                         name="card-billing-address-country-code"
//                         placeholder="Country code"
//                         onChange={(e) =>
//                             handleBillingAddressChange(
//                                 "countryCode",
//                                 e.target.value
//                             )
//                         }
//                     />
//                     <input
//                         type="text"
//                         id="card-billing-address-postal-code"
//                         name="card-billing-address-postal-code"
//                         placeholder="Postal/zip code"
//                         onChange={(e) =>
//                             handleBillingAddressChange("postalCode", e.target.value)
//                         }
//                     />
                    
//                     {/* Custom client component to handle card fields submission */}
//                     <SubmitPayment
//                         isPaying={isPaying}
//                         setIsPaying={setIsPaying}
//                         billingAddress={
//                             billingAddress
//                         } 
//                     />
                    
//                 </PayPalCardFieldsProvider>
                
//             </PayPalScriptProvider>
        
//         );
//     }
    
//     const SubmitPayment = ({ isPaying, setIsPaying, billingAddress }) => {
//         const { cardFieldsForm } = usePayPalCardFields();
    
//         const handleClick = async () => {
//             if (!cardFieldsForm) {
//                 const childErrorMessage =
//                     "Unable to find any child components in the <PayPalCardFieldsProvider />";
    
//                 throw new Error(childErrorMessage);
//             }
//             const formState = await cardFieldsForm.getState();
    
//             if (!formState.isFormValid) {
//                 return alert("The payment form is invalid");
//             }
//             setIsPaying(true);
    
//             cardFieldsForm.submit({ billingAddress }).catch((err) => {
//                 setIsPaying(false);
//             });
//         };
    
//         return (
//             <button
//                 className={isPaying ? "btn" : "btn btn-primary"}
//                 style={{ float: "right" }}
//                 onClick={handleClick}
//             >
//                 {isPaying ? <div className="spinner tiny" /> : "Pay"}
//             </button>
//     );
// }

// export default PayDues;

    //   const initialOptions = {
    //     "client-id": "test",
    //     "enable-funding": "paylater,venmo,card",
    //     "disable-funding": "",
    //     "data-sdk-integration-source": "integrationbuilder_sc",
    //   };
    
    //   const [message, setMessage] = useState("");
    
    //   return (
    //     <div className="App">
    //       <PayPalScriptProvider options={initialOptions}>
    //         <PayPalButtons
    //           style={{
    //             shape: "rect",
    //             layout: "vertical",
    //           }}
    //           createOrder={async () => {
    //             try {
    //               const response = await fetch("/api/orders", {
    //                 method: "POST",
    //                 headers: {
    //                   "Content-Type": "application/json",
    //                 },
    //                 // use the "body" param to optionally pass additional order information
    //                 // like product ids and quantities
    //                 body: JSON.stringify({
    //                   cart: [
    //                     {
    //                       id: "YOUR_PRODUCT_ID",
    //                       quantity: "YOUR_PRODUCT_QUANTITY",
    //                     },
    //                   ],
    //                 }),
    //               });
    
    //               const orderData = await response.json();
    
    //               if (orderData.id) {
    //                 return orderData.id;
    //               } else {
    //                 const errorDetail = orderData?.details?.[0];
    //                 const errorMessage = errorDetail
    //                   ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
    //                   : JSON.stringify(orderData);
    
    //                 throw new Error(errorMessage);
    //               }
    //             } catch (error) {
    //               console.error(error);
    //               setMessage(`Could not initiate PayPal Checkout...${error}`);
    //             }
    //           }}
    //           onApprove={async (data, actions) => {
    //             try {
    //               const response = await fetch(
    //                 `/api/orders/${data.orderID}/capture`,
    //                 {
    //                   method: "POST",
    //                   headers: {
    //                     "Content-Type": "application/json",
    //                   },
    //                 },
    //               );
    
    //               const orderData = await response.json();
    //               // Three cases to handle:
    //               //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
    //               //   (2) Other non-recoverable errors -> Show a failure message
    //               //   (3) Successful transaction -> Show confirmation or thank you message
    
    //               const errorDetail = orderData?.details?.[0];
    
    //               if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
    //                 // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
    //                 // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
    //                 return actions.restart();
    //               } else if (errorDetail) {
    //                 // (2) Other non-recoverable errors -> Show a failure message
    //                 throw new Error(
    //                   `${errorDetail.description} (${orderData.debug_id})`,
    //                 );
    //               } else {
    //                 // (3) Successful transaction -> Show confirmation or thank you message
    //                 // Or go to another URL:  actions.redirect('thank_you.html');
    //                 const transaction =
    //                   orderData.purchase_units[0].payments.captures[0];
    //                 setMessage(
    //                   `Transaction ${transaction.status}: ${transaction.id}. See console for all available details`,
    //                 );
    //                 console.log(
    //                   "Capture result",
    //                   orderData,
    //                   JSON.stringify(orderData, null, 2),
    //                 );
    //               }
    //             } catch (error) {
    //               console.error(error);
    //               setMessage(
    //                 `Sorry, your transaction could not be processed...${error}`,
    //               );
    //             }
    //           }}
    //         />
    //       </PayPalScriptProvider>
    //       <Message content={message} />
    //     </div>
    //   );
