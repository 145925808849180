import React from 'react'
import CJA from'./White-cja.png'
import './cja.css'

const Logo = () => {
    return (
        <div className='logo-card'>
            <div className='logo'>
            <img src={CJA} alt='' className='logo-image'></img> 
            </div>

            <div className='logo-company-name'>
            <p className='logo-p1'>
                CHICAGO JEWELERS' ASSOCIATION</p> 
            <p className='logo-p2'>
                CHICAGO'S BEST RESOURCE FOR JEWELERS AND ASSOCIATES</p> 
        </div>
    </div>
    )
}

export default Logo