import React from 'react';
import {Link} from 'react-router-dom';
import './navbar.css'

const NavBar = () => {

    return (
        <div className='nav-bar'>

            <div className='home'>
                <Link to="/"> Home </Link>
            </div>

            <div className='benefits'>
                <Link to="/benefits"> Benefits </Link>
            </div>

            <div className='paydues'>
                <Link to="/paydues"> Pay Dues </Link>
            </div>

            <div className='contact'>
                <Link to="/contactcja"> Contact CJA </Link>
            </div>   
        </div>
    )
}

export default NavBar;