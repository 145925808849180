import React from 'react';
import './benefits.css'

const Benefits = () => {

    return (
        <div className='benefits-card'>
            <h1 className='h1-title'>CJA Benefits...</h1>

            {/* <div className='parking-discount card'>
                <h3 className='h3-title-benefits'>Parking Discount</h3>
                <p>
                Members of the Chicago Jewelers Association will have the opportunity to have either a deeply discounted monthly or daily rate. 
                In addition there will be a discount voucher available to be given to customers. 
                These rates also apply to the employees of association members.
                </p>
            </div> */}
            
            <div className='professional-networking card'>
                <h3 className='h3-title-benefits'>Professional Networking</h3>
                <p>
                All professionals agree that networking is an important element of success. You need to network to advance yourself and your business. 
                The CJA is an excellent resource for both men and women to interact with their peers. Expand and strengthen your resources through the CJA. 
                Active membership in the association facilitates meeting new industry professionals, solidifying acquaintances and learning new information and ideas. 
                The CJA will help you enhance your professional goals and expand your scope of associates within the industry.
                </p>
            </div>

            <div className='newsletter card'>
                <h3 className='h3-title-benefits'>CJA Newsletter</h3>
                <p>
                Stay on top of jewelry industry activities, business and issues. Read the association’s quarterly newsletter. 
                Members are encouraged to submit articles, information, opinions, advertisements for your business, and items of interest for publication.
                </p>
            </div>

            <div className='educational-programs card'>
                <h3 className='h3-title-benefits'>Educational Programs</h3>
                <p>
                Knowledge is power! Increase your knowledge and your own professional development. 
                Broaden your understanding of a variety of industry topics through the association’s seminars, workshops and round table discussions.
                </p>
            </div>

            <div className='video-library card'>
                <h3 className='h3-title-benefits'>Video Library</h3>
                <p>
                We maintain a library of developmental videotapes. Sharpen your skills and those of your firm’s staff on topics ranging from improving sales skills to optimizing security procedures. 
                This educational resource is free to all members.
                </p>
            </div>

            <div className='activities card'>
                <h3 className='h3-title-benefits'>Activities</h3>
                <li>The CJA provides a social forum for its members to network and socialize.</li>
                <li>Three annual Luncheon/Dinner meetings with guest speakers offering programs of interest.</li>
                <li>The CJA Annual Business meeting and banquet.</li>
                <li>Annual Holiday Party</li>
            </div>

            <div className='philanthropic-program card'>
                <h3 className='h3-title-benefits'>Philanthropic Program</h3>
                <p>
                Continuing education, community involvement and socializing are all integral parts of our association. 
                The CJA strives to better the lives of its members and enrich those within the community. Since 1993, we are proud to have been instrumental in helping 25 students attend colleges and universities. 
                We now have 11 students actively enrolled, working toward a dream. 
                The CJA Scholarship fund has awarded $114,000.00 to students since its inception.
                </p>
            </div>

            <div className='added-value card'>
                <h3 className='h3-title-benefits'>Added Value</h3>
                <p>
                There is strength in numbers. Make your voice heard. The association is an advocate of members concerns to state and local government. 
                It provides members with security updates and a stronger voice with area law enforcement agencies. The CJA closely works with the U.S. 
                Postal Service and other couriers to help reduce industry losses.
                </p>
            </div>

            <div className='security card'>
                <h3 className='h3-title-benefits'>Security</h3>
                <p>
                The CJA continually works to provide the jewelry industry with the most up-to-date security information. 
                In doing so, it has affiliated itself with many organizations such as the Chicago Police Department, The F.B.I., the Jewelers Security Alliance and the JBT. 
                We also participate in the Crime Alert Network to provide timely information on criminal activities effecting our industry. Your safety is our main concern.
                </p>
            </div>

            
            <div className='jewelery-industry card'>
                <h3 className='h3-title-benefits'>A First for the Jewelry Industry!!!</h3>
                <li>The CJA Crime Alert Network</li>
                <li>A “neighborhood crime watch” system for the Jewelry Community. Keeping you informed by e-mail and fax alerts</li>
            </div>


        
        </div>
    )

}

export default Benefits;