const images = [
    'https://www.fodors.com/wp-content/uploads/2019/05/WhatNOTtodoinChicago__HERO_iStock-996188444.jpg',
    'https://assets.editorial.aetnd.com/uploads/2010/03/gettyimages-584791924.jpg',
    'https://upload.wikimedia.org/wikipedia/commons/0/0a/Chicago_Skyline_%28193222927%29.jpeg',
    'https://lp-cms-production.imgix.net/2021-07/GettyRF_95732440.jpg',
    'https://www.fairmont.com/assets/0/104/641/646/648/659/ae013638-3f54-4e9f-aa72-8c68ff703218.png',
    'https://s3.amazonaws.com/static-webstudio-accorhotels-usa-1.wp-ha.fastbooking.com/wp-content/uploads/sites/4/2023/01/18154930/shutterstock_180787010-1170x780.jpg',
    'https://www.experienceflyover.com/getmedia/4455e4ee-9700-41d0-96bf-d8137091dba4/AI-Navy-Pier-Skyline-Sunset.jpg?width=723&height=491&ext=.jpg',
    
];

export default images;

