import React, {useState, useEffect} from 'react';
import images from './img'
import './img.css'


const SlideShow = () => {
    const [CurrentImgIdx, SetCurrentImgIdx] = useState(0);
    const ImgsToShow = 4;

    useEffect(() =>{
        const interval = setInterval(() => {
            SetCurrentImgIdx((prevIdx)=>
                prevIdx + ImgsToShow >= images.length ? 0 : prevIdx + 1
            );
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    const visableImgs = images.slice(CurrentImgIdx, CurrentImgIdx + ImgsToShow);

    return (
        <div className='slideshow-container'>

            {visableImgs.map((image, index)=> (
                <img 
                    key={index}
                    src={image}
                    alt={`slide ${index}`}
                    className='slideshow-image'
                />
              
            ))}
        </div>
    );
}

export default SlideShow;