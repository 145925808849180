import React from 'react';
import './contact.css'

const ContactCJA = () => {

    return (
        <div className='contact-card'>
            
            <h2 className='h2-contact'>Contact Us</h2>

            <h3 className='h3-contact'>Email:</h3>

                <p className='p-contact'>
                    <a href='mailto: admin@chicagoja.org'>Administrator</a>
                </p>
           

            <h3 className='h3-contact'>Mail:</h3>

            <p className='p-contact'>

            Chicago Jewelers' Association<br />
            5 S. Wabash Ave.<br />
            Suite 319 <br />
            Chicago Il 60603 <br />
            
            </p>

        </div>
    )

}

export default ContactCJA;