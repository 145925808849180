import React from 'react'
import './copyrights.css'

const CopyRights = () => {

    return (

    <div className='copyright'>

        <p className='p'> &copy; Copyright 2024 Chicago Jewelers' Association, All Rights reserved. </p>

    </div>

    )
}

export default CopyRights;